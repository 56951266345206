import { useEffect } from 'react'
import lottie from 'lottie-web';
import { useRouter } from 'next/router'
import * as config from '../../next.config'
import {Navbar} from './Layout/Navbar'
import style from "../../styles/mystyle.module.css";
import { useTranslation } from "next-i18next";
export default function NotFound(props) {
    const router = useRouter()
    const {t}=useTranslation()
    useEffect(() => {
        if (typeof window !== "undefined") {
            lottie.loadAnimation({
                container: document.getElementById("notFoundLoader"),
                renderer: "svg",
                loop: true,
                autoplay: true,
                path: `${config.basePath}/json/404.json`
            });
            return ()=>lottie.destroy()
        }
     
    }, [])
    // let href = { pathname: "/" }
    // if (props.url) {
    //     href.pathname = props.url
    // }
    // if (props.query) {
    //     href.query = { next: "/products/checkout" }
    // }

    return (
        <Navbar showDownloadAppSection={false}  exchangeAllCurrenciesCallBack={(res)=>(res)} currenciesCallBack={(res)=>(res)}>
             <div style={{ backgroundColor: "white" }}>
             <div className={style.SectionContainer}>
        <section className="notFoundPage mt-5">
            <div className="container">
                <div className={"flex flex-row-reverse rowMargin"}>
                    <div className=" w-6/12" >
                        <div className={style.notFoundRightBlock}>
                            <h1>
                                {props.number ? props.number : 404}
                            </h1>
                            <h3 style={{textTransform:"capitalize"}}>
                                {props.message ? props.message :t("pageNotFound")}
                            </h3>
                            <button style={{
                                color: "#fff",
                                textTransform:"capitalize",
                                padding: "7px 30px",
                                borderRadius: "30px",
                                marginTop: "10px",
                                display: "inline-block",
                                border: "1px solid  #038E8C",
                                fontSize: "20px",
                                appearance: "none",
                                backgroundColor:"#038E8C"

                            }} onClick={() => router.push("/")}>
                                {props.urlName ? props.urlName : t("Homepage")}
                            </button>
                        </div>
                    </div>
                    <div className="w-6/12 mt-3 ">
                        <div id="notFoundLoader"></div>
                    </div>
                </div>
            </div>
        </section></div>
        </div>
        </Navbar>
    )
}